/* eslint-disable filenames/match-exported */
import { GoogleTagManager } from "@next/third-parties/google";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ReactElement, useEffect } from "react";

import "../public/fonts/Inter-3.19/inter.css";
import "../styles/globals.css";
import "../styles/prism.css";

export default function App({ Component, pageProps }: AppProps): ReactElement {
  const router = useRouter();

  useEffect(() => {
    const debug = process.env.NODE_ENV !== "production";

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? "", {
      api_host: "https://e.foxglove.dev",
      disable_session_recording: true,
      debug,
      sanitize_properties: (properties) => {
        return {
          ...properties,
          Platform: "Web",
          Source: "Website",
        };
      },
    });
  });

  useEffect(() => {
    // Record pageview when route changes
    const routeChangeComplete = (_path: string) => {
      posthog.capture("$pageview");
    };

    router.events.on("routeChangeComplete", routeChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", routeChangeComplete);
    };
  }, [router]);

  return (
    <PostHogProvider client={posthog}>
      <GoogleTagManager gtmId="GTM-TQ5CS696" />
      <Component {...pageProps} />
      <Script id="linkedin-partner-id-integration">
        {
          // https://business.linkedin.com/marketing-solutions/insight-tag
          /* js */ `
_linkedin_partner_id = "6049740";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);
(function(l) {
  if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
  window.lintrk.q=[]}
  var s = document.getElementsByTagName("script")[0];
  var b = document.createElement("script");
  b.type = "text/javascript";b.async = true;
  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  s.parentNode.insertBefore(b, s);
})(window.lintrk);
`
        }
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=6049740&fmt=gif"
        />
      </noscript>
    </PostHogProvider>
  );
}
